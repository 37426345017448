import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';

import { formatCurrency } from "../../components/Util";
import { getStatusClass, startLoading, stopLoading } from "../../components/Util";
import { useAuth } from "../../context/auth";
import * as service from "../../services";

function PODetail(props) {
    let { user } = useAuth();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [poHeader, setPOHeader] = useState({});
    const [comments, setComments] = useState("");
    const [errors, setErrors] = useState({});
    const [cancelModal, setCancelModal] = useState(false);
    const [cancelData, setCancelData] = useState();
    const [cancelcomments, setCancelComments] = useState("");
    const [showAdmin] = useState(user.user_types.is_admin);
    const [showQC] = useState(user.user_types.is_qc);
    const [showSuperUser] = useState(user.user_types.is_sper_user);
    const [adjustExceptionModal, setAdjustExceptionModal] = useState(false);
    const [confirmCancelPO, setConfirmCancelPO] = useState(false);
    const [confirmDelete, setConfirmdelete] = useState(false);
    const [lookups, setLookups] = useState({});
    const [adjustExceptionMode, setAdjustExceptionMode] = useState(undefined);

    const loadData = (fn) => {
        let p1 = new Promise((resolve, reject) => {
            service.get_common_lookups({}, res => {
                setLookups(res);
                resolve("");
            });
        });

        let p2 = new Promise((resolve, reject) => {
            service.get_po_data("get-po-data", { id }, (res) => {
                if (res.data) setPOHeader(res.data);
                else console.log("Record not found");
                resolve("");
            });
        });

        Promise.all([p1, p2])
            .then(values => {
                setLoading(false)
                fn();
            })
            .catch(error => {
                console.log(error.message);
            });
    }

    useEffect(() => {
        startLoading();
        setLoading(true);
        loadData(() => {
            stopLoading();
            setLoading(false);
        });
        // eslint-disable-next-line 
    }, []);

    const handleCommentsChange = (value) => {
        setComments(value);
    };

    const submitComments = () => {
        let payload = { comment_type: "COMMENT", po_id: id, comment_text: comments };
        startLoading();
        setLoading(true);
        service.get_po_data("submit-comments", payload, (res) => {
            if (res.data) {
                loadData(() => {
                    setComments("");
                    stopLoading();
                    setLoading(false);
                });
            } else {
                console.log("Record not found");
                stopLoading();
                setLoading(false);
            }
        });
    }

    const validateData = () => {
        setErrors({});
        let isValid = true;
        let validation = [
            { field: "quantity", msg: "Please enter Quantity." },
            { field: "unit_price", msg: "Please enter Unit Price." },
            { field: "finance_code", msg: "Please select Finance Code." },
        ];

        validation.map((item) => {
            if (!poHeader[item.field] || poHeader[item.field].length <= 0) {
                setErrors((errors) => ({ ...errors, [item.field]: item.msg }));
                isValid = false;
            }
            return item;
        });
        return isValid;
    };

    const submitForApproval = () => {
        if (validateData()) {
            startLoading();
            setLoading(true);
            service.get_po_data("submit-for-approval", { ...poHeader }, (res) => {
                if (res.data) {
                    loadData(() => {
                        stopLoading();
                        setLoading(false);
                    });
                } else {
                    console.log("Record not found");
                    stopLoading();
                    setLoading(false);
                }
            });
        }
    }

    const handleShowCancelModal = (poHeader) => {
        setCancelModal(true);
        setCancelData(poHeader);
    };

    const handleHideCancelModal = () => {
        setCancelModal(false);
    };

    const handleCancelChange = (value) => {
        setCancelComments(value);
    };

    const deletePO = () => {
        startLoading();
        setLoading(true);
        service.get_po_data("delete-po", { id: poHeader.id }, (res) => {
            navigate(-1);
        });
    };

    const CancelPO = () => {
        let payload = {
            comment_type: "CANCELLATION",
            po_id: id,
            comment_text: cancelcomments,
            lifecycle_status: 'CANCELLED'
        };
        startLoading();
        setLoading(true);
        service.get_po_data("update-status", payload, (res) => {
            if (res.data) {
                loadData(() => {
                    setCancelComments("");
                    setCancelModal(false);
                    stopLoading();
                    setLoading(false);
                });
            } else {
                stopLoading();
                setLoading(false);
            }
        });
    }

    const updatePO = () => {
        setErrors({});
        let is_valid = true;
        let { adjustments } = poHeader;
        if (adjustExceptionMode === 'REQUESTED BY') {
            if (!adjustments || (adjustments && !adjustments.requestor_name_new)) {
                is_valid = false;
                setErrors((errors) => ({ ...errors, requestor_name_new: 'Please select Requested By.' }));
            }
        }
        if (adjustExceptionMode === 'CONTRACTOR NAME') {
            if (!adjustments || (adjustments && !adjustments.supplier_name_new)) {
                is_valid = false;
                setErrors((errors) => ({ ...errors, supplier_name_new: 'Please select Contractor Name.' }));
            }
        }
        if (adjustExceptionMode === 'CARE HOME NAME') {
            if (!adjustments || (adjustments && !adjustments.facility_name_new)) {
                is_valid = false;
                setErrors((errors) => ({ ...errors, facility_name_new: 'Please select Care Home Name.' }));
            }
        }
        if (is_valid) {
            let payload = { ...poHeader }
            if (adjustExceptionMode === 'REQUESTED BY') {
                payload.created_by_id = adjustments.requestor_name_new.value;
                payload.requestor_name = adjustments.requestor_name_new.label;
                payload.created_by_linkage_status = 'OK';
                payload.rectify_for = 'user';
            }
            if (adjustExceptionMode === 'CONTRACTOR NAME') {
                payload.contractor_id = adjustments.supplier_name_new.value;
                payload.supplier_name = adjustments.supplier_name_new.label;
                payload.supplier_address = adjustments.supplier_name_new.tier1;
                payload.contractor_linkage_status = 'OK';
                payload.rectify_for = 'contractor';
            }
            if (adjustExceptionMode === 'CARE HOME NAME') {
                payload.care_home_id = adjustments.facility_name_new.value;
                payload.facility_name = adjustments.facility_name_new.label;
                payload.facility_address = adjustments.facility_name_new.tier1;
                payload.care_home_linkage_status = 'OK';
                payload.rectify_for = 'care_home';
            }
            startLoading();
            setLoading(true);
            service.get_po_data("update-po-exception", payload, (res) => {
                loadData(() => {
                    setAdjustExceptionModal(false);
                    stopLoading();
                    setLoading(false);
                });
            });
        }
    }

    const handleChange = (prop, value) => {
        let { quantity, unit_price, finance_code } = poHeader;
        if (prop === "quantity") quantity = value;
        if (prop === "unit_price") unit_price = value;
        if (prop === "finance_code") finance_code = value;
        const price = quantity * poHeader.unit_price;
        const vat = (poHeader.vat_apply ? price * 0.2 : 0);
        const net_price = (poHeader.vat_apply ? price * 1.2 : price);
        const update_set = { quantity, unit_price, price, vat, net_price, finance_code };
        setPOHeader(header => ({ ...header, ...update_set }));
    };

    const handleAdjustExceptionChange = (prop, value) => {
        let adjustments = poHeader.adjustments || {};
        adjustments[prop] = value;
        setPOHeader(header => ({ ...header, adjustments }));
    };

    return (
        <>
            {!loading && poHeader ? <div className="container py-3">
                <div className="row">
                    <div className="col">
                        <h3 style={{ display: "inline", verticalAlign: "middle", paddingRight: "20px" }}>Purchase Order #{poHeader.padded_po_number}</h3>
                        <span className={getStatusClass(poHeader.lifecycle_status_name) + " py-2 px-2"}>
                            {poHeader.lifecycle_status_name}
                        </span>
                    </div>
                    <div className="col flex-grow-0">
                        {(poHeader.lifecycle_status === "APPROVED" || poHeader.lifecycle_status === "CANCELLED") && <button className="btn btn-min btn-success text-white text-nowrap" onClick={() => { service.download_po(poHeader.id, poHeader.padded_po_number, () => { console.log("done") }); }}>Download PO</button>}
                    </div>
                </div>

                <div className="row">
                    <div className="col-6 pt-3">
                        <small className="text-muted">PURCHASE ORDER NUMBER</small>
                        <br />
                        {poHeader.padded_po_number}
                    </div>
                    <div className="col-3 pt-3">
                        <small className="text-muted">SERVICE GROUP</small>
                        <br />
                        {poHeader.service_group}
                    </div>
                    <div className="col-3 pt-3">
                        <small className="text-muted">WORK TYPE</small>
                        <br />
                        {poHeader.work_type}
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 pt-3">
                        <small className="text-muted">CONTRACTOR NAME</small>
                        <br />
                        {poHeader.contractor_linkage_status !== "OK" ?
                            <>
                                <span style={{ color: "#CC0000" }}>{poHeader.supplier_name}</span>
                                {poHeader.approval_status_id === "EXCEPTION" && user.user_types.is_admin && <button className="btn btn-outline-primary btn-sm ms-2" onClick={() => {
                                    setAdjustExceptionMode('CONTRACTOR NAME');
                                    setAdjustExceptionModal(true);
                                }}>Edit</button>}
                            </>
                            : <span>{poHeader.contractor_name}</span>}

                    </div>
                    <div className="col-6 pt-3">
                        <small className="text-muted">CARE HOME NAME</small>
                        <br />
                        {poHeader.care_home_linkage_status !== "OK" ?
                            <>
                                <span style={{ color: "#CC0000" }}>{poHeader.facility_name}</span>
                                {poHeader.approval_status_id === "EXCEPTION" && user.user_types.is_admin && <button className="btn btn-outline-primary btn-sm ms-2" onClick={() => {
                                    setAdjustExceptionMode('CARE HOME NAME');
                                    setAdjustExceptionModal(true);
                                }}>Edit</button>}
                            </>
                            : <span>{poHeader.care_home_name}</span>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 pt-3">
                        <small className="text-muted">CONTRACTOR ADDRESS</small>
                        <br />
                        {poHeader.contractor_linkage_status !== "OK" ?
                            <span style={{ color: "#CC0000" }}>{poHeader.supplier_address && poHeader.supplier_address.split(", ").map((line, i) => <div key={i}>{line}</div>)}</span> : <span>
                                <div>{poHeader.contractor_address_line_1}</div>
                                {poHeader.contractor_address_line_2 ? <div>{poHeader.contractor_address_line_2}</div> : undefined}
                                {poHeader.contractor_town ? <div>{poHeader.contractor_town}</div> : undefined}
                                {poHeader.contractor_county ? <div>{poHeader.contractor_county}</div> : undefined}
                                {poHeader.contractor_postcode ? <div>{poHeader.contractor_postcode}</div> : undefined}
                            </span>}
                    </div>

                    <div className="col-6 pt-3">
                        <small className="text-muted">CARE HOME ADDRESS</small>
                        <br />
                        {poHeader.care_home_linkage_status !== "OK" ?
                            <span style={{ color: "#CC0000" }}>{poHeader.facility_address && poHeader.facility_address.split(", ").map((line, i) => <div key={i}>{line}</div>)}</span> : <span>
                                <div>{poHeader.care_home_address_line_1}</div>
                                {poHeader.care_home_address_line_2 ? <div>{poHeader.care_home_address_line_2}</div> : undefined}
                                {poHeader.care_home_town ? <div>{poHeader.care_home_town}</div> : undefined}
                                {poHeader.care_home_county ? <div>{poHeader.care_home_county}</div> : undefined}
                                {poHeader.care_home_postcode ? <div>{poHeader.care_home_postcode}</div> : undefined}

                            </span>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 pt-3">
                        <small className="text-muted">REQUESTED BY</small>
                        <br />
                        {poHeader.created_by_linkage_status !== "OK" ?
                            <>
                                <span style={{ color: "#CC0000" }}>{poHeader.requestor_name}</span>
                                {poHeader.approval_status_id === "EXCEPTION" && user.user_types.is_admin && <button className="btn btn-outline-primary btn-sm ms-2" onClick={() => {
                                    setAdjustExceptionMode('REQUESTED BY');
                                    setAdjustExceptionModal(true);
                                }}>Edit</button>}
                            </> : <span>{poHeader.created_by_name}</span>}
                    </div>
                </div>
                <div className="row py-4">
                    <div className="col-12">

                        <div className="table-responsive">
                            <table className="table table-bordered po-table m-0">
                                <thead>
                                    <tr>
                                        <th scope="col" width="13%"><h6 className="text-darkprime"><b>Job Number</b></h6></th>
                                        <th scope="col"><h6 className="text-darkprime"><b>Description</b></h6></th>
                                        <th scope="col" width="10%"><h6 className="text-darkprime"><b>Quantity</b></h6></th>
                                        <th scope="col" width="10%"><h6 className="text-darkprime"><b>Unit Price</b></h6></th>
                                        <th scope="col" width="10%"><h6 className="text-darkprime"><b>Extended Price</b></h6></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="border-t0">{poHeader.job_sheet_number}</td>
                                        <td className="border-t0"><p className="mb-0">{poHeader.instructions}</p></td>
                                        <td className="border-t0">{poHeader.lifecycle_status === "CREATED" && (showAdmin || showQC || showSuperUser) ? <div>
                                            <input type="text" className="form-control" value={poHeader.quantity} style={{ textAlign: "right" }}
                                                pattern="[0-9]*"
                                                onKeyUp={(e) => handleChange('quantity', (e.target.validity.valid) ? e.target.value : '')}
                                                onChange={(e) => handleChange('quantity', (e.target.validity.valid) ? e.target.value : '')} />
                                            {errors.quantity && <div className="text-danger"><small>{errors.quantity}</small></div>}
                                        </div> : poHeader.quantity}</td>
                                        <td className="border-t0">{poHeader.lifecycle_status === "CREATED" && (showAdmin || showQC || showSuperUser) ? <div className="row m-0 align-items-center">&#163; &nbsp;<div className="col flex-grow-1 p-0">
                                            <input type="text" className="form-control" value={poHeader.unit_price} style={{ textAlign: "right" }}
                                                pattern="^[0-9]*\.?[0-9]*(\.[0-9]{0,20})?$"
                                                onKeyUp={(e) => handleChange('unit_price', ((e.target.validity.valid) ? e.target.value : ''))}
                                                onChange={(e) => handleChange('unit_price', ((e.target.validity.valid) ? e.target.value : ''))} />
                                            {errors.unit_price && <div className="text-danger"><small>{errors.unit_price}</small></div>}
                                        </div></div> : <span>&#163; {formatCurrency(poHeader.unit_price, 2)}</span>}</td>
                                        <td className="text-end border-t0">&#163; {formatCurrency(poHeader.price, 2)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" rowSpan="4" style={{ borderWidth: '0' }}>
                                            <h6 className="text-darkprime"><strong>Finance Code</strong></h6>
                                            {poHeader.lifecycle_status === "CREATED" && (showAdmin || showQC || showSuperUser) ? <div style={{ width: '90%' }}>
                                                <Select placeholder="Select..."
                                                    value={lookups.FINANCE_CODES ? lookups.FINANCE_CODES.find(fc => fc.value === poHeader.finance_code) : {}}
                                                    options={lookups.FINANCE_CODES || []}
                                                    className="st-select"
                                                    menuPlacement="auto"
                                                    menuPosition="fixed"
                                                    required
                                                    onChange={(e) => { handleChange('finance_code', e.value) }}
                                                />
                                                {errors.finance_code && <div className="text-danger"><small>{errors.finance_code}</small></div>}
                                            </div> : poHeader.finance_code || '-'}
                                        </td>
                                        <td rowSpan="4" style={{ borderWidth: '0' }}>
                                            {poHeader.vat_number && <div>
                                                <h6 className="text-darkprime"><strong>VAT NUMBER</strong></h6>
                                                {poHeader.vat_number}
                                            </div>}
                                        </td>
                                        <td><h6 className="text-darkprime"><strong>Sub Total</strong></h6> </td>
                                        <td className="text-end">&#163; {formatCurrency(poHeader.price, 2)}</td>
                                    </tr>
                                    <tr>
                                        <td><h6 className="text-darkprime"><strong>VAT</strong></h6></td>
                                        <td className="text-end">&#163; {formatCurrency(poHeader.vat, 2)}</td>
                                    </tr>
                                    <tr className="border-0">
                                        <td className="border-b0"><h6 className="text-darkprime"><strong>Grand Total</strong></h6></td>
                                        <td className="border-b0 text-end">&#163; {formatCurrency(poHeader.net_price, 2)}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="col-12 d-flex justify-content-end mt-3">
                                <button type="button" className="btn btn-min btn-secondary text-white" onClick={e => { navigate(-1) }}>Go Back</button>
                                {poHeader.lifecycle_status === "EXCEPTION" && (showAdmin || showQC || showSuperUser) && <button type="button" className="btn btn-min btn-danger text-white ms-2" onClick={e => { setConfirmdelete(true) }}>Delete</button>}
                                {poHeader.lifecycle_status === "CREATED" && (showAdmin || showQC || showSuperUser) && <button type="button" className="btn btn-min btn-success text-white ms-2" onClick={e => { submitForApproval() }}>Submit for Approval</button>}
                                {poHeader.lifecycle_status === "APPROVED" && (showAdmin || showQC || showSuperUser || poHeader.approval_steps.filter(item => item.approver_id === user.id).length > 0) && <button type="button" className="btn btn-min btn-success text-white ms-2" onClick={() => { setConfirmCancelPO(true) }}>Cancel PO</button>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row py-4 comments">
                    <div className="col-12">
                        <h3 className="section-title">Add Comment</h3>
                    </div>
                    <div className="col-12 mt-2">
                        <textarea className="form-control comment-box" rows="2" placeholder="Leave a comment" onChange={(e) => handleCommentsChange(e.target.value)} value={comments}  ></textarea>
                    </div>
                    <div className="col-12 d-flex justify-content-end mt-3">
                        <button type="button" className="btn btn-min btn-success text-white " onClick={submitComments} disabled={comments.trim().length === 0}>Post Comment</button>
                    </div>
                    {poHeader.comments && poHeader.comments.length > 0 && <div className="col-12 mt-3">
                        <div className="col-12">
                            <h5>Comments
                                <span className="badge bg-secondary ms-1 opacity-75 py-1 px-2"><small>{poHeader.comments.filter(item => item.comment_type === "COMMENT").length}</small></span></h5>
                        </div>
                        {poHeader.comments.filter(item => item.comment_type === "COMMENT").map(item => {
                            const commented_at = moment(new Date(item.commented_at_ms));
                            let commented_at_dt = commented_at.format('DD-MMM-YYYY hh:mm A');
                            return <div className="d-flex flex-row p-3 st-comments" key={item.id}>
                                <div className="w-100">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex flex-row align-items-center">
                                            <span className="mr-2"><b>{item.commented_by_name}</b></span>
                                        </div>
                                        <small className="comms-date">{commented_at_dt}</small>
                                    </div>
                                    <p className="text-justify comment-text mb-0">{item.comment_text}</p>
                                </div>
                            </div>
                        })}
                    </div>}
                </div>
                {poHeader.approval_steps && poHeader.approval_steps.length > 0 && <div className="row py-4 progress">
                    <div className="col-12">
                        <h3 className="section-title">Approvals</h3>
                    </div>
                    <div className="col-12 pt-2">
                        <ul className="progress-indicator">
                            {poHeader.approval_steps.map(item => {
                                let color = "pending";
                                let icon = ""
                                if (item.status_id === "PENDING") {
                                    color = "pending"
                                    icon = "pending"
                                }
                                if (item.status_id === "APPROVED") {
                                    color = "completed";
                                    icon = "thumb_up";
                                }
                                if (item.status_id === "REJECTED") {
                                    color = "danger";
                                    icon = "thumb_down";
                                }
                                if (item.status_id === "ESCALATED") {
                                    color = "danger";
                                    icon = "arrow_circle_right";
                                }
                                if (item.is_active_step) {
                                    color = "active";
                                    icon = "pending_actions";
                                }
                                return <li key={item.id} className={color}>
                                    <i className="material-symbols-sharp" >{icon}</i>
                                    <span className="bubble"></span>
                                    <span className="text-body">{item.approver_name}</span>
                                </li>
                            })}
                        </ul>
                    </div>
                </div>}
                {poHeader.approval_status_id === "CANCELLED" && <div className="col-12 mt-3">
                    <div className="col-12">
                        <h3 className="section-title">Cancellation Comments</h3>
                    </div>
                    {poHeader.comments.filter(item => item.comment_type === "CANCELLATION").map(item => {
                        const commented_at = moment(new Date(item.commented_at_ms));
                        let commented_at_dt = commented_at.format('DD-MMM-YYYY hh:mm A');

                        return <div className="d-flex flex-row p-3" key={item.id}>
                            <div className="w-100">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex flex-row align-items-center">
                                        <span className="mr-2"><b>{item.commented_by_name}</b></span>
                                    </div>
                                    <small className="comms-date">{commented_at_dt}</small>
                                </div>
                                <p className="text-justify comment-text mb-0">{item.comment_text}</p>
                            </div>
                        </div>
                    })}
                </div>}
            </div> : <div className="blank-div">Record not found</div>}

            {cancelModal ? <div className="modal-backdrop fade show"></div> : ""}
            <div className={"modal fade " + (cancelModal ? "show d-block" : "d-none")} id="staticBackdrop" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-2">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="text-left">Cancel PO</h4>
                                </div>
                                <div className="col-12 mt-2">
                                    <textarea className="form-control comment-box" rows="4" placeholder="Cancellation Note" onChange={(e) => handleCancelChange(e.target.value)} value={cancelcomments}></textarea>
                                </div>
                            </div>

                            <div className="d-grid gap-2 d-md-flex justify-content-md-center mt-3">
                                <button className="btn btn-secondary" type="button" onClick={handleHideCancelModal}>Close</button>
                                <button className="btn btn-primary text-white" type="button" onClick={() => CancelPO(cancelData)} disabled={cancelcomments.trim().length === 0} >Cancel PO</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {confirmCancelPO ? <div className="modal-backdrop fade show"></div> : ""}
            <div className={"modal fade " + (confirmCancelPO ? "show d-block" : "d-none")} id="staticBackdrop" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-3">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col">
                                    <h5 className="text-center">Are you sure you want to Cancel PO?</h5>
                                </div>
                            </div>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-center mt-3">
                                <button className="btn btn-secondary" type="button" onClick={() => setConfirmCancelPO(false)}>No </button>
                                <button className="btn btn-primary text-white" type="button" onClick={() => {
                                    setConfirmCancelPO(false);
                                    handleShowCancelModal()
                                }}>Yes </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {confirmDelete ? <div className="modal-backdrop fade show"></div> : ""}
            <div className={"modal fade " + (confirmDelete ? "show d-block" : "d-none")} id="staticBackdrop" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-3">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col">
                                    <h5 className="text-center">Are you sure you want to Delete PO?</h5>
                                </div>
                            </div>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-center mt-3">
                                <button className="btn btn-secondary" type="button" onClick={() => setConfirmdelete(false)}>No </button>
                                <button className="btn btn-primary text-white" type="button" onClick={() => {
                                    setConfirmdelete(false);
                                    deletePO();
                                }}>Yes </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {adjustExceptionModal ? <div className="modal-backdrop fade show"></div> : ""}
            <div className={"modal fade " + (adjustExceptionModal ? "show d-block" : "d-none")} id="staticBackdrop" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-2">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 mb-0 pb-0">
                                    <h4 className="text-left">Update <span style={{ 'color': '#28a852' }}>{adjustExceptionMode}</span></h4>
                                    <hr />
                                </div>
                                <div className="col-12">
                                    {adjustExceptionMode === 'REQUESTED BY' &&
                                        <div className="row">
                                            <div className="col-6">
                                                <small className="text-muted">REQUESTED BY</small>
                                                <br />
                                                <span style={{ color: "#CC0000" }}>{poHeader.requestor_name}</span>
                                            </div>
                                            <div className="col-6">
                                                <small className="text-muted">REQUESTED BY</small>
                                                <br />
                                                <Select placeholder="Select..."
                                                    value={poHeader?.adjustments?.requestor_name_new}
                                                    options={lookups.USERS || []}
                                                    className="st-select"
                                                    menuPlacement="auto"
                                                    required
                                                    onChange={(e) => { handleAdjustExceptionChange('requestor_name_new', e) }}
                                                />
                                                {errors.requestor_name_new && <div className="text-danger"><small>{errors.requestor_name_new}</small></div>}
                                            </div>
                                        </div>
                                    }
                                    {adjustExceptionMode === 'CONTRACTOR NAME' &&
                                        <>
                                            <div className="row">
                                                <div className="col-6">
                                                    <small className="text-muted">CONTRACTOR NAME</small>
                                                    <br />
                                                    <span style={{ color: "#CC0000" }}>{poHeader.supplier_name}</span>
                                                </div>
                                                <div className="col-6">
                                                    <small className="text-muted">CONTRACTOR NAME</small>
                                                    <br />
                                                    <Select placeholder="Select..."
                                                        value={poHeader?.adjustments?.supplier_name_new}
                                                        options={lookups.CONTRACTOR || []}
                                                        className="st-select"
                                                        menuPlacement="auto"
                                                        required
                                                        onChange={(e) => { handleAdjustExceptionChange('supplier_name_new', e) }}
                                                    />
                                                    {errors.supplier_name_new && <div className="text-danger"><small>{errors.supplier_name_new}</small></div>}
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6">
                                                    <small className="text-muted">CONTRACTOR ADDRESS</small>
                                                    <br />
                                                    <span style={{ color: "#CC0000" }}>{poHeader.supplier_address && poHeader.supplier_address.split(", ").map((line, i) => <div key={i}>{line}</div>)}</span>
                                                </div>
                                                <div className="col-6">
                                                    <small className="text-muted">CONTRACTOR ADDRESS</small>
                                                    <br />
                                                    <span>{poHeader?.adjustments?.supplier_name_new ? poHeader?.adjustments?.supplier_name_new?.tier1?.split(", ").map((line, i) => <div key={i}>{line}</div>) : '-'}</span>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {adjustExceptionMode === 'CARE HOME NAME' &&
                                        <>
                                            <div className="row">
                                                <div className="col-6">
                                                    <small className="text-muted">CARE HOME NAME</small>
                                                    <br />
                                                    <span style={{ color: "#CC0000" }}>{poHeader.facility_name}</span>
                                                </div>
                                                <div className="col-6">
                                                    <small className="text-muted">CARE HOME NAME</small>
                                                    <br />
                                                    <Select placeholder="Select..."
                                                        value={poHeader?.adjustments?.facility_name_new}
                                                        options={lookups.CARE_HOME || []}
                                                        className="st-select"
                                                        menuPlacement="auto"
                                                        required
                                                        onChange={(e) => { handleAdjustExceptionChange('facility_name_new', e) }}
                                                    />
                                                    {errors.facility_name_new && <div className="text-danger"><small>{errors.facility_name_new}</small></div>}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <small className="text-muted">CARE HOME ADDRESS</small>
                                                    <br />
                                                    <span style={{ color: "#CC0000" }}>{poHeader.facility_address && poHeader.facility_address.split(", ").map((line, i) => <div key={i}>{line}</div>)}</span>
                                                </div>
                                                <div className="col-6">
                                                    <small className="text-muted">CARE HOME ADDRESS</small>
                                                    <br />
                                                    <span>{poHeader?.adjustments?.facility_name_new ? poHeader?.adjustments?.facility_name_new?.tier1?.split(", ").map((line, i) => <div key={i}>{line}</div>) : '-'}</span>
                                                </div>
                                            </div>
                                        </>}
                                </div>
                            </div>

                            <div className="d-grid gap-2 d-md-flex justify-content-md-center mt-3">
                                <button className="btn btn-secondary" type="button" onClick={() => {
                                    setAdjustExceptionMode(undefined);
                                    let _poHeader = poHeader;
                                    delete _poHeader.adjustments;
                                    setPOHeader(_poHeader);
                                    setAdjustExceptionModal(false);
                                }}>Cancel</button>
                                <button className="btn btn-primary text-white" type="button" onClick={() => { updatePO() }}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PODetail;
